/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import {graphql, useStaticQuery} from "gatsby";

const Social = () => {
    const data = useStaticQuery(graphql`
        query SocialQuery {
            site {
                siteMetadata {
                    author
                    social {
                        email
                        github
                        linkedin
                        twitter
                    }
                }
            }
        }
    `)

    const {email, github, linkedin, twitter} = data.site.siteMetadata.social
    return (
        <ul className="icons">
            <li>
                <a
                    href={"mailto:" + email}
                    className="icon fa-envelope"
                >
                    <span className="label">Email</span>
                </a>
            </li>
            <li>
                <a
                    href={linkedin}
                    className="icon fa-linkedin"
                >
                    <span className="label">LinkedIn</span>
                </a>
            </li>
            <li>
                <a
                    href={github}
                    className="icon fa-github"
                >
                    <span className="label">GitHub</span>
                </a>
            </li>
            <li>
                <a
                    href={twitter}
                    className="icon fa-twitter"
                >
                    <span className="label">Twitter</span>
                </a>
            </li>
        </ul>
    )
}

export default Social
