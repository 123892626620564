import PropTypes from 'prop-types'
import React from 'react'
import pic03 from '../images/pic03.jpg'
import logo from "../images/haveneer-logo-drakkar.svg";
import cloud from "../images/wordcloud2_outline.min.svg"
import Social from "./social";

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <div id="logo"
             className={`active ${this.props.articleTimeout ? 'timeout' : ''}`}
             style={{ display: "none" }}
        >
          <img src={logo} alt={"logo"}/>
        </div>
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`}
          style={{ display: 'none' }}
        >
          <h2 style={{color: "white"}} className="major">About Me</h2>
          (ou plus classiquement un <a href="https://www.haveneer.com/CV.pdf">CV</a> avec références)
          <div style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}>
            <div className="image center">
              <img src={cloud} style={{width: "100%"}} alt="" />
            </div>
          </div>
          <p style={{margin: "0 0"}}>Pour construire toujours plus haut, il convient d'avoir une bonne architecture et de bonnes fondations.
            C'est dans ce sens, que je déploie mes compétences, ma méthodologie afin de concevoir des systèmes fiables
            et innovants.</p>

          <p style={{margin: "1rem 0"}}>Mon terrain de prédilection est historiquement le calcul scientifique (version High Performance Computing
            avec un solide background mathématique). J'ai ensuite déployé mes compétences plus largement vers les
            environnements distribués et leurs nombreuses applications dans nos écosystèmes modernes.</p>

          <p style={{margin: "1rem 0"}}>Mon objectif est de développer avec vous et vos équipes des techniques et des architectures pour une meilleure
            productivité dans vos projets de développement logiciel. Mes prestations de conseil, d'expertise et de formation s'adaptent
            à vos besoins, votre environment, vos équipes. Une attention toute particulière est donnée à la transmission des concepts sous-jacents et les "bonnes
            pratiques" pour concevoir des systèmes performants, robustes et durables.</p>

          <p style={{margin: "1rem 0"}}>Les domaines couverts s'étendent de la programmation (concepttion et développement orientée objet, orientée donnée et fonctionnelle; algorithmique)
            aux environnements de production et de la qualité logicielle (git, GitHub, GitLab, docker...) en passant par des technologies structurantes de l'information (base de données,
            sécurité, réseau...).</p>

          <p>Skills:
            <ul>
              <li>Architecture et qualité logicielles (principalement sur des codes Orientés Objets)</li>
              <li>Programmation Parallèle et Haute Performance (poussée jusqu'à plusieurs milliers de processeurs)</li>
              <li>C/C++, programmation orientée objets (expert de longue date, dedans depuis presque 20 ans)</li>
              <li>La programmation fonctionnelle (j'y retrouve ma passion de la rigueur, des maths et de l'esthétisme dans la programmation) employée à travers divers langages même non purement fonctionnels (C++, Rust, Python)</li>
              <li>SysOps/DevOps (Unix/Linux/MacOS/Windows)</li>
              <li>Outils et environnement de développement (CI/CD, Docker, Analyse de code...)</li>
              <li>Passeur de savoir (<i>aka</i> formateur mais aussi amateur de bonnes discussions ouvertes tant techniques que sociétales)</li>
            </ul>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`}
          style={{ display: 'none' }}
        >
          <h2 style={{color: "white"}} className="major">Contact</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p style={{margin: "0rem 0"}}>
            Pascal Havé : <a href={"mailto:contact@haveneer.com"}>contact@haveneer.com</a>
          </p>
          <p style={{margin: "1rem 0"}}>
          Et retrouvez-moi sur:
            <Social/>
          </p>
          <p>
          <b style={{color: "white"}}>haveneer</b> est une EURL immatriculée 840 647 366 au R.C.S. de Nanterre<br/>
          </p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
