import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import logo from '../images/haveneer-logo-drakkar.svg'
import "../assets/css/typography.css"

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        {/*<div className="logo">*/}
    	{/*	<img src={logo} style={{width: "100%"}}/>*/}
        {/*</div>*/}
        <div className="content">
            <div className="inner">
                <div className="big-logo">
		            <img src={logo} style={{width: "50%"}} alt="logo"/>
                </div>
		        <div style={{fontFamily: "Snell Roundhand, Snell Roundhand Local, cursive", fontSize: "5vmin" }}>
		            Ajoutez une autre dimension
		        </div>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:" onClick={() => {props.onOpenArticle('about')}}>About</a></li>
                <li><a href="https://blog.haveneer.com">Blog</a></li>
                {/*<li><Link to="/blog">Blog</Link></li>*/}
                <li><a href="javascript:" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
